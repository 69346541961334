<template>
    <div class="container-fluid py-4">
      <div class="row row-style">
        <div class="col-12">
          <form id="fazenda-form" @submit.prevent="gerarRegistro">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="company">Nome da Empresa</label>
                <input type="text" class="form-control" id="company" v-model="formData.company"
                  placeholder="Digite o nome da empresa" required>
              </div>
              <div class="form-group py-3">
                <button type="submit" class="btn btn-purple" style="width: 100px;">Salvar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
  data() {
    return {
      formData: {
        company: '',
        company_type: 'fazenda',
        company_children: localStorage.getItem('company'),
        status: 1,
      },
      servidor: 'https://api001.bia.ind.br',
    };
  },
  methods: {
    async gerarRegistro() {
      if (!localStorage.getItem('company')) {
        alert('ERRO: Ocorreu um erro inesperado, por favor contate o administrador do sistema.')
      }

      try {
        // Post para criar ou atualizar dados da empresa
        const response = await axios.post(this.servidor + '/api/companies', this.formData);

        this.$router.push('/dashboard-sub-empresas');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
  
<style scoped>
.form-control {
  border-color: #ced4da;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}
</style>