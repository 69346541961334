<template>
    <div class="container-fluid py-4">
      <div class="row row-style">
        <div class="col-12">
          <form id="users-form" @submit.prevent="gerarRegistro">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="nome">Nome</label>
                <input type="text" class="form-control" id="nome" v-model="formData.name" placeholder="Digite o nome">
              </div>
              <div class="form-group col-md-6">
                <label for="sobrenome">Sobrenome</label>
                <input type="text" class="form-control" id="sobrenome" v-model="formData.last_name" placeholder="Digite o sobrenome">
              </div>
              <div class="form-group col-md-6">
                <label for="email">E-mail</label>
                <input type="email" class="form-control" id="email" v-model="formData.email" placeholder="Digite o e-mail do usuário">
              </div>
              <div class="form-group col-md-6">
                <label for="senha">Senha</label>
                <input type="password" class="form-control" id="senha" v-model="formData.password" placeholder="Digite uma senha">
              </div>
              <div class="form-group col-md-3">
                <label for="conta">Tipo de Conta</label>
                <select class="form-control" id="conta" v-model="formData.account_type">
                  <option value="" disabled>SELECIONE UM TIPO DE CONTA</option>
                  <option value="administrador">Admintrador</option>
                  <option value="funcionário">Funcionário</option>
                </select>
              </div>
              <div class="form-group col-md-3" v-if="this.user.fk_company === 1 && this.user.account_type == 'administrador'">
                <label for="empresa">Empresa</label>
                <select class="form-control" id="empresa" v-model="formData.fk_company">
                  <option value="" disabled>SELECIONE UMA FAZENDA</option>
                  <option v-for="item in filteredList" :key="item.id" :value="item.id">{{ item.company.toUpperCase() }}</option>
                </select>
              </div>
              <div class="form-group col-12 py-3">
                <button type="submit" class="btn btn-purple w-100">Salvar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
<script>
  import axios from 'axios';

  export default {
    data() {
      return {
        user: {},
        servidor: 'https://api001.bia.ind.br',
        list: [],
        formData: {
          name: '',
          last_name: '',
          email: '',
          password: '',
          account_type: '',
          fk_company: localStorage.getItem('company'),
          status: 1,
        },
        
      }
    },
    mounted() {
      this.getUserData()
      this.getList()

      // Verifique se há um ID na URL
      const userId = this.$route.query.id;
      if (userId) {
        this.getUserById(userId);
      }
    },
    computed: {
      filteredList() {
        return this.list.filter(listItem =>
          Object.values(listItem).some(value =>
            String(value)
          )
        );
      },
    },
    methods: {
      async gerarRegistro() {
      try {
        // Se houver um ID, envie uma solicitação de atualização
        if (this.$route.query.id) {
          const response = await axios.put(
            `${this.servidor}/api/users/${this.$route.query.id}`,
            this.formData
          );
        } else {
          // Caso contrário, envie uma solicitação de criação
          const response = await axios.post(
            `${this.servidor}/api/users`,
            this.formData
          );
        }
        // Redirecione após salvar
        this.$router.push('/dashboard-usuarios');
        } catch (error) {
          console.error(error);
        }
      },

      getList() {
        axios.get(this.servidor + '/api/companies', {
            headers: {
              'Accept': 'application/json',
            },
        })
            .then(response => {
                this.list = response.data
            })
            .catch(error => {
                console.error(error)
            })
      },

      getUserData() {
        axios.get(this.servidor + '/api/user', {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        })
        .then(response => {
          this.user = response.data;
        })
        .catch(error => {
          console.error(error)
        });
      },

      getUserById(userId) {
      axios.get(`${this.servidor}/api/users/${userId}`)
        .then(response => {
          // Preencha o formulário com os dados do usuário
          this.formData = response.data;
        })
        .catch(error => {
          console.error(error);
        });
      },

    },
  };
</script>
  
  <style scoped>
  .form-control {
    border-color: #ced4da;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  </style>