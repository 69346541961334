<template>
    <div class="container">
      <Termos />
    </div>
</template>
  
  <script>
  import Termos from '@/components/Termos.vue';
  
  export default {
    name: 'TermosView',  // Nome do componente exportado
    components: {
      Termos,
    },
  }
  </script>
  
  <style>
  
  </style>
  