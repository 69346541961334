<template>
  <div class="container-fluid py-4">
    <div class="row row-style">
      <div class="table-responsive-lg col-12">

        <div class="card col-md-12" id="table-card">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <input type="text" class="form-control search-table" v-model="searchTerm" placeholder="Pesquisar">
              </div>
            </div>

            <!--
            <div class="col-md-3">
              <div class="form-group">
                <select class="form-control" id="empresa">
                  <option value="" disabled selected>SELECIONE UMA FAZENDA</option>
                  <option></option>
                </select>
              </div>
            </div>
            -->

          </div>
        </div>
        <table class="table table-hover table-pastel mb-0 mt-0">
          <thead class="thead-dark">
            <tr>
              <th>Nome</th>
              <th>Sysbov</th>
              <th>Microship</th>
              <th>Últ. cobertura</th>
              <th>Prev. secagem</th>
              <th>Data de nascimento</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-capitalize" v-for="item in filteredList" :key="item.id">
              <td>{{ item.surname }}</td>
              <td>{{ item.sysbov }}</td>
              <td>{{ item.microship }}</td>
              <td>{{ item.last_coverage != '0000-00-00' ? this.formatDate(item.last_coverage) : 'Não informado' }}</td>
              <td>{{ item.drying != '0000-00-00' ? this.formatDate(item.drying) : 'Não informado' }}</td>
              <td>{{ this.formatDate(item.birth) }}</td>
              <td>
                <button type="button" class="btn btn-purple rounded-0" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.stop="openItemModal(item)">
                  Detalhes
                </button>


              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content rounded-0">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">DETALHES</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <div v-if="selectedItem">
            <form id="bezerro-form" @submit.prevent="editarPlantel" enctype="multipart/form-data">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="nome">Nome do animal</label>
                  <input type="text" class="form-control" id="nome" v-model="selectedItem.surname" readonly
                    placeholder="Digite o nome do animal">
                </div>
                <div class="form-group col-md-6">
                  <label for="sysbov">Sysbov</label>
                  <input type="number" class="form-control" id="sysbov" v-model="selectedItem.sysbov" readonly
                    placeholder="sysbov">
                </div>
                <div class="form-group col-md-6">
                  <label for="microship">Microship</label>
                  <input type="text" class="form-control" id="microship" v-model="selectedItem.microship" readonly
                    placeholder="Microship">
                </div>

                <div class="form-group col-md-6">
                  <label for="last_coverage">Últ. cobertura</label>
                  <input type="date" class="form-control" id="last_coverage" v-model="selectedItem.last_coverage" readonly
                    placeholder="dd/mm/aaaa">
                </div>

                <div class="form-group col-md-6">
                  <label for="drying">Prev. secagem</label>
                  <input type="date" class="form-control" id="drying" v-model="selectedItem.drying" readonly
                    placeholder="dd/mm/aaaa">
                </div>

                <div class="form-group col-md-6">
                  <label for="childbirth">Prev. parto</label>
                  <input type="date" class="form-control" id="childbirth" v-model="selectedItem.childbirth" readonly
                    placeholder="dd/mm/aaaa">
                </div>

                <div class="form-group col-md-6">
                  <label for="dataNascimento">Data de nascimento</label>
                  <input type="date" class="form-control" id="dataNascimento" v-model="selectedItem.birth" readonly
                    placeholder="dd/mm/aaaa">
                </div>
                <div class="form-group col-md-6">
                  <label for="genero">Gênero</label>
                  <select class="form-control" id="genero" v-model="selectedItem.gender" readonly>
                    <option value="" disabled>SELECIONE UM GÊNERO</option>
                    <option value="m">Macho</option>
                    <option value="f">Fêmea</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <div class="modal-footer">
            <button type="button" class="btn btn-primary rounded-0" @click="editarPlantel(item)">Alterar</button>

          </div>

          <button type="button" class="btn btn-secondary rounded-0" data-bs-dismiss="modal"
            @click="closeModal">Fechar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import "@/assets/css/table.css";
import { format } from 'date-fns';

export default {
  data() {
    return {
      searchTerm: '',
      list: [],
      company: localStorage.getItem('subEmpresaSelecionada') ?? localStorage.getItem('company'), 
      servidor: 'https://api001.bia.ind.br',
      selectedItem: null,
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    filteredList() {
      const term = this.searchTerm.toLowerCase()
      return this.list.filter(listItem =>
        Object.values(listItem).some(value =>
          String(value).toLowerCase().includes(term)

        )

      );
    },

  },
  methods: {
    getList() {
      axios.get(`${this.servidor}/api/squads/get-by-company/${this.company}`, {
        headers: {
          'Accept': 'application/json',
        },
      })
        .then(response => {
          this.list = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    openItemModal(item) {
      this.selectedItem = item;
      console.log("esse e o id para abrir model:", this.selectedItem)

    },

    closeModal() {
      this.selectedItem = null;
    },

    editarPlantel(item) {
      // Redirecione para a página de criação/editação com o ID do item
      if (this.selectedItem) {
        var modalBackdrop = document.querySelector('.modal-backdrop.fade.show');
        var modal = document.querySelector('.modal.fade.show');

        if (modalBackdrop && modal) {
            modalBackdrop.parentNode.removeChild(modalBackdrop);
            modal.parentNode.removeChild(modal);
        }

        this.$router.push({
          path: '/novo-animal',
          query: { id: this.selectedItem.id }
        });
      }
    },

    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy');
    },
  },
};
</script>


<style>
.row-style {
  margin: auto !important;
}

.thead-dark>tr>th {
  background-color: #fff !important
}

#table-card {
  background-color: #9E14D9db !important;
  box-shadow: #9E14D9 0px 1px 7px 0px;
  border: 0;
  height: 58px;
  padding: 0;
}

.modal-title {
  font-weight: bold;
}

.btn-purple {
  background-color: #9E14D9db;
  border-color: #9E14D9db;
  color: #fff !important;
}

tbody tr {
  line-height: 5;
}
</style>