<template>
  <div class="container-fluid py-4">
    <div class="row row-style">
      <div class="col-12">
        <form id="serial-form" @submit.prevent="gerarRegistro">
          <div class="row">
            <div class="form-group col-12">
              <label for="serial">Serial</label>
              <input type="text" class="form-control" id="serial" v-model="formData.serial"
                placeholder="Digite o serial da raquete">
            </div>
            <div class="form-group col-5 py-3">
              <button type="submit" class="btn btn-purple w-100">Salvar</button>
            </div>
            <div class="form-group col-5 py-3">
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      formData: {
        serial: '',
        status: 1,
        fk_company: localStorage.getItem('company'),
      },
      servidor: 'https://api001.bia.ind.br',
      editMode: false,
      id: null,
    };
  },
  async created() {
    // Verifica se há um ID na URL
    this.id = this.$route.query.id;
    if (this.id) {
      this.editMode = true;
      await this.getRegistro();
    }
  },
  methods: {
    async getRegistro() {
      try {
        const response = await axios.get(`${this.servidor}/api/rackets/${this.id}`);
        this.formData = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async editar() {
      try {
        if (this.editMode) {
          const response = await axios.put(`${this.servidor}/api/rackets/${this.id}`, this.formData);
          console.log(response.data);
        } else {
          const response = await axios.post(this.servidor + '/api/rackets', this.formData);
          console.log(response.data);
        }
        this.$router.push('/dashboard-raquetes');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.form-control {
  border-color: #ced4da;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}
</style>
