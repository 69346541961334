<template>
  <div class="vr-container-login container text-center">
    <div class="row">
      <div class="vr-container-two col p-3">
        <div class="vr-img-login">
          <img src="\img\logo2.png" alt="img_login_inicial">
        </div>
        <form @submit.prevent="login">
          <div class="mb-3">
            <div class="vr-input-input-login">
              <label class="form-label vr-label-login" for="usuario">E-mail:</label>
              <input class="form-control vr-input-login" id="exampleInputEmail1" aria-describedby="emailHelp" type="email" v-model="email" required>
            </div>
            <div class="mb-3 vr-input-input-login">
              <label class="form-label vr-label-login" for="usuario">Senha:</label>
              <input class="form-control vr-input-login" id="exampleInputPassword1" type="password" v-model="password" required>
            </div>
            <div class="mb-3 vr-input-input-login">
              <button type="submit" class="vr-input-submit">Enviar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/login.css";
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: '',
      isLoggedIn: false,
      servidor: 'https://api001.bia.ind.br'
    };
  },
  mounted() {
    if(localStorage.getItem('token'))
    {
      this.$router.push('/dashboard-leituras');
        }
  },
  methods: {
  async login() {
    try {
      const response = await axios.post(`${this.servidor}/api/login`, {
        email: this.email,
        password: this.password
      });

      localStorage.setItem('token', response.data.token);
      this.$router.push('/dashboard-leituras');
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      alert('Usuário ou senha inválidos!');
    }
  }
}

};
</script>

<style scoped>
  .vr-container-login {
    max-width: 1950px;
    width: 100%;
    margin: 0 auto;
    height: 950px;
    padding: 0;
    background-color: #9932CC;
    display: flex;
    justify-content: center;
    align-items: center;


  }

  .vr-container-one, .vr-container-two{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vr-container-one {
    width: 35%;
    height: 100%;
    margin-right: 0px;
    padding: 0px;
    background-color: #9932CC;

    align-items: center;
    flex-direction: column;
  }

  .vr-container-two {
    width: 400px;
    height: 100%;
    margin-left: 0px;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-shadow: 6px 6px 12px rgba(12, 12, 12, 0.2);
    
  }

  form {
    width: 300px;
  }

  .vr-text {
    width: 100%;
    height: 20%;
    margin-bottom: 10px;
  }

  .vr-text-p {
    color: #FFF;
    text-align: center;
    font-size: 45px;
  }

  .vr-blue{
    width: 100%;
    height: 126px; 
    background: #87CEFA; 
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    
  }

  .vr-blue-p {
    color: #FFF;
    text-align: center;
  }

  .vr-img-login {
    width: 146px;
    height: 146px; 
  }
  .vr-img-login img{
    width: 100%;
    height: 100%;
  }

  .vr-input-input-login {
    display: flex;
    flex-direction: column;
    margin: 0 auto; /* Centraliza o formulário na tela */
    max-width: 500px; /* Define a largura máxima do formulário */
  }

  .vr-label-login {
    width: 20%;
    height: 30%; 

    color: #000;
    text-align: center;
    font-size: 20px;
    line-height: normal; 

    align-self: flex-start;
    margin-top: 14px;
    margin-bottom: 5px;
  }

  .vr-input-login {
    width: 100%;
    height: 40px; 
    margin-bottom: 14px;
    background: #F0F8FF;
    border-radius: 0;
  }
  .vr-input-submit{
    width: 100%;
    height: 100%; 
    background: #9932CC;
    color: #FFF;
    text-align: center;
    font-size: 25px;
    font-weight: 400;
    line-height: normal; 
    border: 0;
    padding: 10px 0;

  }

  @media (max-width: 575px) {
    .vr-container-one, .vr-container-two {
        width: 100%;
        margin: 0;
    }
    .vr-container-two {
        box-shadow: none;
    }
    .vr-container-login {
        max-width: 100%; /* Remove a largura máxima */
        width: 100%;
        margin: 0;
        height: auto; /* Ajuste a altura automaticamente */
        padding: 20px; /* Adiciona algum espaço ao redor do contêiner */
        background-color: #9932CC;
        display: flex;
        flex-direction: column; /* Empilhe os elementos verticalmente */
        justify-content: center;
        align-items: center;
    }

    .vr-container-one, .vr-container-two {
        width: 100%; /* Preencha a largura total da tela */
        margin: 0;
        padding: 10px; /* Adicione espaço ao redor dos elementos */
    }

    .vr-container-one {
        background-color: #9932CC;
    }

    .vr-container-two {
        background-color: white;
        box-shadow: 6px 6px 12px rgba(12, 12, 12, 0.2);
    }

    /* Estilos para textos */
    .vr-text-p {
        color: #FFF;
        text-align: center;
        font-family: initial;
        font-size: 25px; /* Reduz o tamanho da fonte para telas menores */
        font-weight: 400;
        line-height: normal;
        margin: 10px 0; /* Aumenta o espaçamento entre os textos */
    }

    /* Estilos para a seção azul */
    .vr-blue {
        height: auto; /* Ajusta a altura automaticamente */
        background: #87CEFA;
        padding: 10px;
    }

    .vr-blue-p {
        color: #FFF;
        text-align: center;
        font-family: initial;
        font-size: 20px; /* Reduz o tamanho da fonte para telas menores */
        font-weight: 400;
        line-height: normal;
    }

    /* Estilos para imagens */
    .vr-img-login {
        width: 100px; /* Reduz o tamanho da imagem */
        height: 100px;
    }

    .vr-img-login img {
        width: 100%;
        height: 100%;
    }

    /* Estilos para os campos de entrada */
    .vr-input-input-login {
        max-width: 300px; /* Define uma largura máxima para os campos de entrada */
    }

    .vr-label-login {
        width: 100%;
        height: auto; /* Ajusta a altura automaticamente */
        color: #000;
        text-align: left; /* Alinhe o texto à esquerda para telas menores */
        font-family: Arbutus;
        font-size: 18px; /* Reduz o tamanho da fonte para telas menores */
        font-weight: 400;
        line-height: normal;
        margin: 10px 0; /* Aumenta o espaçamento entre os rótulos */
    }

    .vr-input-login {
        width: 100%;
        height: 40px; /* Reduz a altura dos campos de entrada */
        margin: 10px 0;
        box-shadow: none; /* Remove a sombra para telas menores */
    }

    .vr-input-submit {
        width: 100%;
        height: 40px; /* Reduz a altura do botão de envio */
        font-size: 18px; /* Reduz o tamanho da fonte para telas menores */
    }
}

/* Estilos para telas médias, como tablets */
@media (min-width: 576px) and (max-width: 767px) {
    .vr-container-login {
        height: 600px; /* Ajuste a altura para tablets */
    }
    .vr-container-one {
        width: 50%;
    }
    .vr-container-two {
        width: 90%;
    }
    /* Estilos padrão para todas as telas */
    .vr-container-login {
        max-width: 100%; /* Remove a largura máxima */
        width: 100%;
        margin: 0;
        height: auto; /* Ajuste a altura automaticamente */
        padding: 20px; /* Adiciona algum espaço ao redor do contêiner */
        background-color: #9932CC;
        display: flex;
        flex-direction: column; /* Empilhe os elementos verticalmente */
        justify-content: center;
        align-items: center;
    }

    .vr-container-one, .vr-container-two {
        width: 100%; /* Preencha a largura total da tela */
        margin: 0;
        padding: 10px; /* Adicione espaço ao redor dos elementos */
    }

    .vr-container-one {
        background-color: #9932CC;
    }

    .vr-container-two {
        background-color: white;
        box-shadow: 6px 6px 12px rgba(12, 12, 12, 0.2);
    }

    /* Estilos para textos */
    .vr-text-p {
        color: #FFF;
        text-align: center;
        font-family: initial;
        font-size: 25px; /* Reduz o tamanho da fonte para telas menores */
        font-weight: 400;
        line-height: normal;
        margin: 10px 0; /* Aumenta o espaçamento entre os textos */
    }

    /* Estilos para a seção azul */
    .vr-blue {
        height: auto; /* Ajusta a altura automaticamente */
        background: #87CEFA;
        padding: 10px;
    }

    .vr-blue-p {
        color: #FFF;
        text-align: center;
        font-family: initial;
        font-size: 20px; /* Reduz o tamanho da fonte para telas menores */
        font-weight: 400;
        line-height: normal;
    }

    /* Estilos para imagens */
    .vr-img-login {
        width: 100px; /* Reduz o tamanho da imagem */
        height: 100px;
    }

    .vr-img-login img {
        width: 100%;
        height: 100%;
    }

    /* Estilos para os campos de entrada */
    .vr-input-input-login {
        max-width: 300px; /* Define uma largura máxima para os campos de entrada */
    }

    .vr-label-login {
        width: 100%;
        height: auto; /* Ajusta a altura automaticamente */
        color: #000;
        text-align: left; /* Alinhe o texto à esquerda para telas menores */
        font-family: Arbutus;
        font-size: 18px; /* Reduz o tamanho da fonte para telas menores */
        font-weight: 400;
        line-height: normal;
        margin: 10px 0; /* Aumenta o espaçamento entre os rótulos */
    }

    .vr-input-login {
        width: 100%;
        height: 40px; /* Reduz a altura dos campos de entrada */
        margin: 10px 0;
        box-shadow: none; /* Remove a sombra para telas menores */
    }

    .vr-input-submit {
        width: 100%;
        height: 40px; /* Reduz a altura do botão de envio */
        font-size: 18px; /* Reduz o tamanho da fonte para telas menores */
    }
}

  @media (min-width: 992px) {  }

  @media (min-width: 1200px) {  }

  @media (min-width: 1400px) {  }
</style>