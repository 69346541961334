<template>
    <div class="container">
        <div v-if="view === 'terms'">
        <h1>Termos e Condições</h1>
        <p>
            <strong>Termos e Condições</strong>
        </p>
        <p>
            Estes termos e condições aplicam-se ao aplicativo Veterinário Vaca Roxa (doravante referido como "Aplicativo") para dispositivos móveis, criado pela VACA ROXA INDÚSTRIA DE EQUIPAMENTOS CLÍNICOS LTDA (doravante referido como "Prestador de Serviço") como um serviço gratuito.
        </p>
        <p>
            Ao baixar ou utilizar o Aplicativo, você está automaticamente concordando com os seguintes termos. É altamente recomendável que você leia e compreenda estes termos antes de usar o Aplicativo. É estritamente proibida a cópia não autorizada, modificação do Aplicativo, qualquer parte do Aplicativo ou de nossas marcas registradas. Tentativas de extrair o código-fonte do Aplicativo, traduzir o Aplicativo para outros idiomas ou criar versões derivadas não são permitidas. Todas as marcas registradas, direitos autorais, direitos de banco de dados e outros direitos de propriedade intelectual relacionados ao Aplicativo permanecem como propriedade do Prestador de Serviço.
        </p>
        <p>
            O Prestador de Serviço está dedicado a garantir que o Aplicativo seja o mais útil e eficiente possível. Assim, reserva-se o direito de modificar o Aplicativo ou cobrar pelos serviços a qualquer momento e por qualquer motivo. O Prestador de Serviço garante que quaisquer cobranças pelo Aplicativo ou por seus serviços serão claramente comunicadas a você.
        </p>
        <p>
            O Aplicativo armazena e processa dados pessoais que você forneceu ao Prestador de Serviço para fornecer o Serviço. É sua responsabilidade manter a segurança do seu telefone e o acesso ao Aplicativo. O Prestador de Serviço recomenda fortemente contra o desbloqueio ("jailbreaking") ou "rooting" do seu telefone, que envolve a remoção de restrições e limitações impostas pelo sistema operacional oficial do seu dispositivo. Tais ações podem expor seu telefone a malware, vírus, programas maliciosos, comprometer os recursos de segurança do seu telefone e podem resultar no não funcionamento correto ou total do Aplicativo.
        </p>
        <p>
            Observe que o Aplicativo utiliza serviços de terceiros que têm seus próprios Termos e Condições. Abaixo estão os links para os Termos e Condições dos provedores de serviços de terceiros utilizados pelo Aplicativo:
            <ul>
                <li>             Google Play Services
                </li>
                <li>             Google Analytics para Firebase
                </li>
                <li>            Firebase Crashlytics
                </li>
            </ul>
        </p>
        <p>
            Esteja ciente de que o Prestador de Serviço não assume responsabilidade por certos aspectos. Algumas funções do Aplicativo requerem uma conexão ativa com a Internet, que pode ser Wi-Fi ou fornecida pelo seu provedor de rede móvel. O Prestador de Serviço não pode ser responsabilizado se o Aplicativo não funcionar plenamente devido à falta de acesso ao Wi-Fi ou se você tiver esgotado sua franquia de dados.
        </p>
        <p>
            Se você estiver usando o Aplicativo fora de uma área de Wi-Fi, esteja ciente de que os termos do acordo com seu provedor de rede móvel ainda se aplicam. Consequentemente, você pode incorrer em cobranças do seu provedor móvel pelo uso de dados durante a conexão com o Aplicativo ou outras cobranças de terceiros. Ao usar o Aplicativo, você aceita a responsabilidade por tais cobranças, incluindo cobranças de dados de roaming se usar o Aplicativo fora do seu território de origem (ou seja, região ou país) sem desativar o roaming de dados. Se você não for o responsável pelo pagamento da conta do dispositivo no qual está usando o Aplicativo, assume-se que você obteve permissão do responsável pelo pagamento.
        </p>
        <p>
            Da mesma forma, o Prestador de Serviço não pode sempre assumir responsabilidade pelo seu uso do Aplicativo. Por exemplo, é sua responsabilidade garantir que seu dispositivo permaneça carregado. Se seu dispositivo ficar sem bateria e você não puder acessar o Serviço, o Prestador de Serviço não pode ser responsabilizado.
        </p>
        <p>
            Em termos de responsabilidade do Prestador de Serviço pelo seu uso do Aplicativo, é importante observar que, embora eles se esforcem para garantir que ele esteja atualizado e preciso em todos os momentos, eles dependem de terceiros para fornecer informações para disponibilizá-las a você. O Prestador de Serviço não aceita responsabilidade por qualquer perda, direta ou indireta, que você possa experimentar como resultado de confiar inteiramente nessa funcionalidade do Aplicativo.
        </p>
        <p>
            O Prestador de Serviço pode querer atualizar o Aplicativo em algum momento. O Aplicativo está atualmente disponível conforme os requisitos para o sistema operacional (e para quaisquer sistemas adicionais que decidam estender a disponibilidade do Aplicativo) podem mudar, e você precisará baixar as atualizações se quiser continuar usando o Aplicativo. O Prestador de Serviço não garante que sempre atualizará o Aplicativo para que seja relevante para você e/ou compatível com a versão específica do sistema operacional instalada no seu dispositivo. No entanto, você concorda em sempre aceitar as atualizações do Aplicativo quando oferecidas. O Prestador de Serviço também pode desejar cessar a oferta do Aplicativo e pode encerrar seu uso a qualquer momento, sem aviso prévio. A menos que informem o contrário, após qualquer rescisão, (a) os direitos e licenças concedidos a você nestes termos terminarão; (b) você deve parar de usar o Aplicativo e (se necessário) excluí-lo do seu dispositivo.
        </p>
        <h2>Alterações a Estes Termos e Condições</h2>
        <p>
            O Prestador de Serviço pode periodicamente atualizar seus Termos e Condições. Portanto, é aconselhável revisar esta página regularmente para quaisquer alterações. O Prestador de Serviço notificará você de quaisquer mudanças, publicando os novos Termos e Condições nesta página.
        </p>
        <p>
            Estes termos e condições são efetivos a partir de 29 de junho de 2024.
        </p>
        <h2>Contato</h2>
        <p>
            Se você tiver qualquer dúvida ou sugestão sobre os Termos e Condições, não hesite em contatar o Prestador de Serviço através do e-mail vacaroxaa@gmail.com.
        </p>
        <button @click="view = 'privacy'">Ir para Política de Privacidade</button>
      </div>
      <div v-else-if="view === 'privacy'">
        <h1>Política de Privacidade</h1>
 <p>
    <strong>Política de Privacidade</strong>

 </p>       
<p>
  Esta política de privacidade se aplica ao aplicativo Veterinário Vaca Roxa (doravante referido como "Aplicativo") para dispositivos móveis, criado pela VACA ROXA INDÚSTRIA DE EQUIPAMENTOS CLÍNICOS LTDA (doravante referido como "Prestador de Serviço") como um serviço gratuito. Este serviço é destinado para uso "COMO ESTÁ".
</p>
<h2>Coleta e Uso de Informações</h2>
<p>
  O Aplicativo coleta informações quando você o baixa e utiliza. Essas informações podem incluir:
</p>
<ul>
  <li>O endereço de Protocolo de Internet do seu dispositivo (por exemplo, endereço IP)</li>
  <li>As páginas do Aplicativo que você visita, a hora e a data da sua visita, o tempo gasto nessas páginas</li>
  <li>O tempo gasto no Aplicativo</li>
  <li>O sistema operacional que você utiliza no seu dispositivo móvel</li>
</ul>
<p>
  O Aplicativo não coleta informações precisas sobre a localização do seu dispositivo móvel.
</p>
<p>
  O Prestador de Serviço pode usar as informações fornecidas por você para entrar em contato ocasionalmente, para fornecer informações importantes, avisos necessários e promoções de marketing.
</p>
<p>
  Para uma melhor experiência ao usar o Aplicativo, o Prestador de Serviço pode solicitar que você forneça certas informações pessoalmente identificáveis, incluindo, mas não se limitando a e-mail e senha. As informações solicitadas pelo Prestador de Serviço serão retidas por ele e utilizadas conforme descrito nesta política de privacidade.
</p>
<h2>Acesso de Terceiros</h2>
<p>
  Somente dados agregados e anonimizados são periodicamente transmitidos a serviços externos para ajudar o Prestador de Serviço a melhorar o Aplicativo e seu serviço. O Prestador de Serviço pode compartilhar suas informações com terceiros nas maneiras descritas nesta declaração de privacidade.
</p>
<p>
  Observe que o Aplicativo utiliza serviços de terceiros que têm suas próprias Políticas de Privacidade sobre o manejo de dados. Abaixo estão os links para a Política de Privacidade dos provedores de serviços de terceiros utilizados pelo Aplicativo:
</p>
<ul>
  <li>Google Play Services</li>
  <li>Google Analytics para Firebase</li>
  <li>Firebase Crashlytics</li>
</ul>
<p>
  O Prestador de Serviço pode divulgar as Informações Fornecidas pelo Usuário e as Informações Coletadas Automaticamente:
</p>
<ul>
  <li>conforme exigido por lei, como para cumprir uma intimação ou processo legal semelhante;</li>
  <li>quando acreditarem de boa fé que a divulgação é necessária para proteger seus direitos, proteger sua segurança ou a segurança de outros, investigar fraude ou responder a uma solicitação do governo;</li>
  <li>com seus provedores de serviços confiáveis que trabalham em seu nome, não têm um uso independente das informações que divulgamos a eles e concordaram em aderir às regras estabelecidas nesta declaração de privacidade.</li>
</ul>
<h2>Direitos de Opt-Out</h2>
<p>
  Você pode parar toda a coleta de informações pelo Aplicativo facilmente desinstalando-o. Você pode utilizar os processos padrão de desinstalação que podem estar disponíveis como parte do seu dispositivo móvel ou através do mercado de aplicativos móveis ou rede.
</p>
<h2>Política de Retenção de Dados</h2>
<p>
  O Prestador de Serviço reterá os dados fornecidos pelo usuário enquanto você utilizar o Aplicativo e por um tempo razoável após isso. Se você desejar que eles excluam os dados fornecidos pelo usuário que você forneceu por meio do Aplicativo, entre em contato através do e-mail vacaroxaa@gmail.com e eles responderão em um tempo razoável.
</p>
<h2>Crianças</h2>
<p>
  O Prestador de Serviço não utiliza o Aplicativo para solicitar dados ou fazer marketing para crianças menores de 13 anos. O Aplicativo não é direcionado a ninguém com menos de 13 anos. O Prestador de Serviço não coleta conscientemente informações pessoalmente identificáveis de crianças menores de 13 anos. Caso o Prestador de Serviço descubra que uma criança menor de 13 anos forneceu informações pessoais, ele as excluirá imediatamente de seus servidores. Se você é pai ou responsável e está ciente de que seu filho forneceu informações pessoais, entre em contato com o Prestador de Serviço (vacaroxaa@gmail.com) para que possam tomar as medidas necessárias.
</p>
<h2>Segurança</h2>
<p>
  O Prestador de Serviço está preocupado em proteger a confidencialidade de suas informações. O Prestador de Serviço fornece salvaguardas físicas, eletrônicas e processuais para proteger as informações que processa e mantém.
</p>
<h2>Alterações</h2>
<p>
  Esta Política de Privacidade pode ser atualizada de tempos em tempos por qualquer motivo. O Prestador de Serviço notificará você de quaisquer alterações à Política de Privacidade atualizando esta página com a nova Política de Privacidade. Você é aconselhado a consultar esta Política de Privacidade regularmente para quaisquer alterações, pois o uso contínuo é considerado aprovação de todas as mudanças.
</p>
<p>
  Esta política de privacidade é efetiva a partir de 29 de junho de 2024.
</p>
<h2>Seu Consentimento</h2>
<p>
  Ao usar o Aplicativo, você consente com o processamento de suas informações conforme estabelecido nesta Política de Privacidade agora e conforme alterado por nós.
</p>
<h2>Contato</h2>
<p>
  Se você tiver qualquer dúvida sobre privacidade ao usar o Aplicativo, ou tiver perguntas sobre as práticas, entre em contato com o Prestador de Serviço via e-mail em vacaroxaa@gmail.com.
</p>

        <button @click="view = 'terms'">Voltar para Termos e Condições</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        view: 'terms'  // Estado inicial para exibir os Termos e Condições
      }
    }
  }
  </script>
  
  <style>
  .container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  p {
    text-align: justify;
    margin-bottom: 20px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  </style>
  