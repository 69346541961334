<template>
    <nav class="col-md-2 d-md-block sidebar">
        <div class="sidebar-sticky">
            <ul class="nav flex-column">
                <!-- <li class="nav-item">
                    <button class="btn btn-block btn-sidebar" @click="navigateTo('/home')">
                        <i class="fas fa-home"></i> Home
                    </button>
                </li> -->
                <li class="nav-item">
                    <div class="dropdown">
                        <button class="btn btn-block btn-sidebar dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="fas fa-table-list"></i> Dashboards
                        </button>
                        <ul class="dropdown-menu dropdown-menu-ligth menu-dropdown">
                            <li>
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/dashboard-plantel')">
                                    <i class="fas fa-cow"></i> Plantel
                                </button>
                            </li>
                            <li v-if="user.fk_company === 1 && user.account_type == 'administrador'">
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/dashboard-empresas')">
                                    <i class="fas fa-wheat-awn"></i> Empresas
                                </button>
                            </li>
                            <li>
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/dashboard-sub-empresas')">
                                    <i class="fas fa-wheat-awn"></i> Sub Empresas
                                </button>
                            </li>
                            <li>
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/dashboard-empresas-vinculadas')">
                                    <i class="fas fa-wheat-awn"></i> Empresas Vinculadas
                                </button>
                            </li>
                            <li>
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/dashboard-raquetes')">
                                    <i class="fas fa-list-ul"></i> Raquetes
                                </button>
                            </li>
                            <li>
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/dashboard-leituras')">
                                    <i class="fas fa-magnet"></i> Leituras
                                </button>
                            </li>
                            <li>
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/dashboard-usuarios')">
                                    <i class="fas fa-users"></i> Usuários
                                </button>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item">
                    <button class="btn btn-block btn-sidebar" @click="navigateTo('/novo-animal')">
                        <i class="fas fa-cow"></i> Cadastrar Animal
                    </button>
                </li>
                <li class="nav-item">
                    <div class="dropdown">
                        <button class="btn btn-block btn-sidebar dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="fas fa-gear"></i> Configurações
                        </button>
                        <ul class="dropdown-menu dropdown-menu-ligth menu-dropdown">
                            <li v-if="user.fk_company === 1 && user.account_type == 'administrador'" >
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/nova-empresa')">
                                    <i class="fas fa-wheat-awn"></i> Cadastrar Empresa
                                </button>
                            </li>
                            <li>
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/nova-sub-empresa')">
                                    <i class="fas fa-wheat-awn"></i> Cadastrar Sub Empresa
                                </button>
                            </li>
                            <li>
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/nova-vinculacao')">
                                    <i class="fas fa-wheat-awn"></i> Vincular empresas
                                </button>
                            </li>
                            <li>
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/nova-raquete')">
                                    <i class="fas fa-list-ul"></i> Cadastrar Raquete
                                </button>
                            </li>
                            <!-- <li v-if="user.fk_company === 1 && getListCompaniesId(user.foreign_key_farm) === 'vaca roxa' && user.account_type == 'administrador' ">
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/nova-raca')">
                                    <i class="fas fa-magnet"></i> Cadastrar Raça
                                </button>
                            </li> -->
                            <li>
                                <button class="btn btn-block btn-sidebar" @click="navigateTo('/novo-usuario')">
                                    <i class="fas fa-users"></i> Cadastrar Usuário
                                </button>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <button class="btn btn-success d-lg-none w-100 rounded-0" @click="logout" data-toggle="modal"
                        data-target="#logoutModal">
                        Sair
                    </button>
                </li>
            </ul>
        </div>
    </nav>
</template>
  
<script>
import axios from 'axios';

export default {
    name: "Sidebar",
    data() {
        return {
            user: {},
            listCompanies: [],
            servidor: 'https://api001.bia.ind.br',
        }
    },
    mounted() {
    const navbarToggler = document.querySelector('.navbar-toggler');
    const sidebar = document.querySelector('.sidebar');

    if (navbarToggler && sidebar) {
        navbarToggler.addEventListener('click', () => {
            sidebar.classList.toggle('show');
        });
    }

    this.getUserData();
    this.getListCompanies();
},

    methods: {
        navigateTo(page) {
            this.$router.push(page)
        },
        logout() {
            localStorage.removeItem('isLoggedIn');
            // Redirecionar para a página de login
            this.$router.push('/');
        },
        getUserData() {
            axios.get(this.servidor + '/api/user', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
                .then(response => {
                    this.user = response.data;
                })
                .catch(error => {
                    console.error(error)
                });
        },
        getListCompanies() {
            axios.get(this.servidor + '/api/companies', {
                headers: {
                    'Accept': 'application/json',
                },
            })
                .then(response => {
                    this.listCompanies = response.data
                })
                .catch(error => {
                    console.error(error)
                })
        },
        getListCompaniesId(id) {
            const company = this.listCompanies.find(item => item.id === id);
            return company ? company.company : '';
        },
    }
}
</script>

<style scoped>
.menu-dropdown {
    position: relative !important;
    inset: auto !important;
    margin: 0px !important;
    transform: none !important;
    border-radius: 0 !important;
    background-color: #9E14D942 !important;
}

.btn-sidebar:hover {
    background-color: #9E14D942 !important;
    color: #000 !important;
    border-color: transparent !important;
}

.btn-sidebar.show {
    border-color: transparent !important;
    background-color: #9E14D9bd !important;
    color: #ffffff;
}

.btn-sidebar.show:hover {
    color: #ffffff !important;
}

.btn-sidebar.show i {
    color: #ffffff;
}

.btn-sidebar i {
    width: 20px;
}
</style>