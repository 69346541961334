<template>
  <div class="container-fluid py-4">
    <div class="row row-style">
      <div class="col-12">
        <form id="vinculacao-form" @submit.prevent="vincular">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="company">CPF/CNPJ Empresa</label>
              <div style="display: flex; gap: 10px;">
                <input type="text" class="form-control" id="company" v-model="cnpj" @input="formatCnpj"
                placeholder="Digite o CPF ou CNPJ da empresa" required>
                <button class="btn btn-primary" @click="getSearchEmpresas" type="button"> <i class="fas fa-search"></i> </button>
              </div>
            </div>
            <div class="form-group col-md-12" v-if="searchMessage">
              <p>{{ searchMessage }}</p>
            </div>
            <div class="form-group py-3">
              <button type="submit" class="btn btn-purple" style="width: 100px;">Vincular</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      formData: {
        company: '',
        company_type: 'fazenda',
        company_children: localStorage.getItem('company'),
        status: 1,
      },
      cnpj: '',
      parentId: '',
      parentType: '',
      servidor: 'https://api001.bia.ind.br',
      searchMessage: '', // Nova variável de estado para a mensagem de pesquisa
    };
  },
  methods: {
    async vincular() {
      let id = localStorage.getItem('company');
      let form = {
        company_id: id,
        company_parent_laticinio: this.parentType === 'laticinio' ? this.parentId : null,
        company_parent_veterinario: this.parentType === 'veterinario' ? this.parentId : null
      };
      try {
        // Post para criar ou atualizar dados da empresa
        const response = await axios.post(`${this.servidor}/api/companies/link`, form);

        alert('Empresa vinculada com sucesso.');
        //this.$router.push('/dashboard-sub-empresas');
      } catch (error) {
        console.error(error);
      }
    },

    formatCnpj() {
      // Remove caracteres não numéricos
      let value = this.cnpj.replace(/\D/g, '');

      // Verifica se é CPF ou CNPJ
      if (value.length <= 11) {
        // CPF
        this.cnpj = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4').substring(0, 18);
      } else {
        // CNPJ
        this.cnpj = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5').substring(0, 18);
      }
    },

    async getSearchEmpresas() {
      try {
        const response = await axios.post(`${this.servidor}/api/companies/get-by-cnpj`, { cnpj: this.cnpj });
        console.log(response);
        if (response.data.length > 0) {
          this.searchMessage = response.data[0].company;
          this.parentId = response.data[0].id;
          this.parentType = response.data[0].company_type;
        } else {
          this.searchMessage = 'Empresa não encontrada no banco de dados.';
        }
      } catch (error) {
        console.error('Erro ao buscar empresa:', error);
        this.searchMessage = 'Ocorreu um erro ao buscar a empresa. Por favor, tente novamente.';
      }
    },
  },
};
</script>

<style scoped>
.form-control {
  border-color: #ced4da;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}
</style>