<template>
  <div class="container-fluid py-4">
    <div class="col-12">
      <form id="bezerro-form" @submit.prevent="submitRegistro()" enctype="multipart/form-data">
        <div class="row">
          <div class="form-group col-md-6">
            <label for="surname">Nome do animal <span>(Opcional)</span></label>
            <input type="text" class="form-control" id="surname" v-model="surname" placeholder="Digite o nome do animal"
              required>
          </div>

          <div class="form-group col-md-6">
            <label for="sysbov">Sysbov</label>
            <input type="number" class="form-control" id="sysbov" v-model="sysbov" placeholder="Sysbov" required>
          </div>

          <div class="form-group col-md-6">
            <label for="microship">Microship</label>
            <input type="text" class="form-control" id="microship" v-model="microship" placeholder="Microship" required>
          </div>

          <div class="form-group col-md-3">
            <label for="birth">Data de nascimento</label>
            <input type="date" class="form-control" id="birth" v-model="birth" placeholder="dd/mm/aaaa" required>
          </div>

          <div class="form-group col-md-3">
            <label for="last_coverage">últ. cobertura</label>
            <input type="date" class="form-control" id="last_coverage" v-model="last_coverage" placeholder="dd/mm/aaaa">
          </div>

          <div class="form-group col-md-3">
            <label for="drying">Prev. secagem</label>
            <input type="date" class="form-control" id="drying" v-model="drying" placeholder="dd/mm/aaaa">
          </div>

          <div class="form-group col-md-3">
            <label for="childbirth">Prev. parto</label>
            <input type="date" class="form-control" id="childbirth" v-model="childbirth" placeholder="dd/mm/aaaa">
          </div>

          <div class="form-group col-md-3">
            <label for="gender">Gênero</label>
            <select class="form-control" id="gender" v-model="gender">
              <option value="" disabled>SELECIONE UM GÊNERO</option>
              <option value="m">Macho</option>
              <option value="f">Fêmea</option>
            </select>
          </div>

          <div class="form-group col-6 py-3">
            <button type="submit" class="btn btn-warning w-100">Atualizar</button>
          </div>
          <div class="form-group col-6 py-3">
            <button type="button" class="btn btn-danger w-100" @click="confirmarExclusaoPlantel">Excluir</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      surname: '',
      sysbov: '',
      microship: '',
      tag: '',
      birth: '',
      gender: '',
      last_coverage: '',
      drying: '',
      childbirth: '',
      image: '',
      fk_company: localStorage.getItem('subEmpresaSelecionada') ?? localStorage.getItem('company'),
      status: 1,
      id: null, // Adiciona um campo para armazenar o id
      servidor: 'https://api001.bia.ind.br',
      isEditMode: false,
    };
  },
  mounted() {

  },
  created() {
    // Tenta obter o id do animal da URL
    this.id = this.$route.query.id;

    // Se houver um id, carregue os dados do animal
    if (this.id) {
      this.getPlantelData().then(() => {
        // Salva os dados originais do animal após a conclusão do carregamento
        this.originalSquadData = { ...this.$data };
        this.isEditMode = true;
      });
    }
  },
  methods: {
    async getPlantelData() {
      try {
        const response = await axios.get(`${this.servidor}/api/squads/${this.id}`);
        this.surname = response.data.surname;
        this.sysbov = response.data.sysbov;
        this.microship = response.data.microship;
        this.tag = response.data.tag;
        this.birth = response.data.birth;
        this.gender = response.data.gender;
        this.last_coverage = response.data.last_coverage;
        this.drying = response.data.drying;
        this.childbirth = response.data.childbirth;
        this.fk_company = this.fk_company;
        this.status = response.data.status;

        if (response.data.image) {
          this.image = response.data.image;
        }
      } catch (error) {
        console.error(error);
      }
    },

    //Submit
    submitRegistro() {
      if (this.id) {
        this.atualizarRegistro();
      } else {
        this.gerarRegistro();
      }
    },

    // Editar o animal
    async atualizarRegistro() {
      const axios = require('axios');
      let data = JSON.stringify({
        "surname": this.surname,
        "sysbov": this.sysbov,
        "microship": this.microship,
        "tag": this.microship,
        "birth": this.birth,
        "gender": this.gender,
        "last_coverage": this.last_coverage,
        "drying": this.drying,
        "childbirth": this.childbirth,
        "fk_company": this.fk_company,
        "status": this.status
      });

      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: this.servidor + '/api/squads/' + this.id,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

      const response = await axios.request(config);

      console.log('Resposta do servidor:', response.data);
      console.log("Animal atualizado"),
        //redireciona a rota
        this.$router.push('/dashboard-plantel')


    },

    onImagemSelecionada(event) {
      this.image = event.target.files[0];
    },

    gerarRegistro(event) {
      const formData = new FormData();
      formData.append('surname', this.surname);
      formData.append('sysbov', this.sysbov);
      formData.append('microship', this.microship);
      formData.append('tag', this.microship);
      formData.append('birth', this.birth);
      formData.append('gender', this.gender);
      formData.append('last_coverage', this.last_coverage);
      formData.append('drying', this.drying);
      formData.append('childbirth', this.childbirth);
      formData.append('image', this.image);
      formData.append('fk_company', this.fk_company);
      formData.append('status', this.status);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      axios.post(`${this.servidor}/api/squads`, formData, config)
        .then(response => {
          console.log("Animal cadastrado");
          this.$router.push('/dashboard-plantel');
        })
        .catch(error => {
          console.error("Erro durante o cadastro do animal:", error);
          if (error.response) {
            if (error.response.status === 409) {
              alert(error.response.data.message);
            } else {
              console.error(error.response.data.error);
            }
          } else {
            console.error(error.message);
          }
        });
    },

    async confirmarExclusaoPlantel() {
      // Use o método de confirmação do navegador
      console.log("essa bomba", this.id)
      if (window.confirm('Tem certeza que deseja excluir o plantel?')) {
        // Chame o método de exclusão aqui
        await this.deletePlantel();
      } else {
        // Ação cancelada pelo usuário
        console.log('Exclusão do plantel cancelada.');
      }
    },

    async deletePlantel() {
      const axios = require('axios');
      let data = JSON.stringify({
        "surname": this.surname,
        "sysbov": this.sysbov,
        "microship": this.microship,
        "tag": this.microship,
        "birth": this.birth,
        "gender": this.gender,
        "last_coverage": this.last_coverage,
        "drying": this.drying,
        "childbirth": this.childbirth,
        "fk_company": this.fk_company,
        "status": 1
      });

      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: this.servidor + '/api/squads/' + this.id,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

      const response = await axios.request(config);

      console.log('Resposta do servidor:', response.data);
      console.log("Animal Excluido"),
        //redireciona a rota
        this.$router.push('/dashboard-plantel')


    },






  },




}

</script>

<style scoped>
.form-control {
  border-color: #ced4da;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}
</style>