<template>
  <div class="container-fluid py-4">
    <div class="row row-style">
      <div class="table-responsive-lg col-12">
        <div class="card col-md-12" id="table-card">
          <div class="form-group">
            <input type="text" class="form-control search-table" v-model="searchTerm" placeholder="Pesquisar">
          </div>
        </div>
        <table class="table table-hover table-pastel mb-0 mt-0">
          <thead class="thead-dark">
            <tr>
              <th>Serial</th>
              <th>Data do cadastro</th>
              <th>Opções</th>

            </tr>
          </thead>
          <tbody>
            <tr class="text-capitalize" v-for="item in filteredList" :key="item.id">
              <td>{{ item.serial }}</td>
              <td>{{ formatDate(item.updated_at) }}</td>

              <td>
                <button class="btn btn-purple rounded-0" @click="editRaquetes(item.id)">Detalhes</button>

                <button type="submit" @click="deleteRaquete(item.id)"
                  class="btn btn btn-purple rounded-0">Excluir</button>

              </td>
            </tr>
          </tbody>
        </table>
        <pagination :data="list" :per-page="perPage" @pagination-change-page="handlePageChange">1</pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Pagination from 'vue-pagination-2';
import "@/assets/css/table.css";

export default {
  data() {
    return {
      searchTerm: '',
      list: [],
      company: localStorage.getItem('company'),
      servidor: 'https://api001.bia.ind.br',
      currentPage: 1,
      perPage: 10,
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    filteredList() {
      const term = this.searchTerm.toLowerCase()

      return this.list.filter(listItem =>
        Object.values(listItem).some(value =>
          String(value).toLowerCase().includes(term)
        )
      );
    },
    paginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.list.slice(start, end);
    },
  },
  methods: {
    getList() {
      console.log("id", this.company),

        axios.get(this.servidor + '/api/rackets/get-by-company/' + this.company, {
          headers: {
            'Accept': 'application/json',
          },
        })

          .then(response => {
            this.list = response.data
          })
          .catch(error => {
            console.error(error)
          })
    },

    handlePageChange(page) {
      this.currentPage = page;
    },


    deleteRaquete(itemId) {
      if (confirm('Tem certeza que deseja excluir esta raquete?')) {
        axios.delete(`${this.servidor}/api/rackets/${itemId}`)
          .then(response => {
            this.getList(); // Atualizar a lista após a exclusão
            console.log('Raquete excluída:', response.data);
          })
          .catch(error => {
            console.error('Erro ao excluir raquete:', error);
          });
      }
    },


    editRaquetes(itemid) {
      // Redirecione para a página de criação/editação com o ID do usuário
      this.$router.push({
        path: '/nova-raquete',
        query: { id: itemid }

      });
      console.log("id", itemid)

    },


    formatDate(date) {
      return new Date(date).toLocaleString('pt-BR', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      });
    },
  },
};
</script>

<style>
.row-style {
  margin: auto !important;
}

.thead-dark>tr>th {
  background-color: #fff !important
}

#table-card {
  background-color: #9E14D9db !important;
  box-shadow: #9E14D9 0px 1px 7px 0px;
  border: 0;
  height: 58px;
  padding: 0;
}

.modal-title {
  font-weight: bold;
}

.btn-purple {
  background-color: #9E14D9db;
  border-color: #9E14D9db;
  color: #fff !important;
}

tbody tr {
  line-height: 5;
}
</style>