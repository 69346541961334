<template>
  <div class="container-fluid py-4">
    <div class="row row-style">
      <div class="table-responsive-lg col-12">
        
        <div class="card col-md-12" id="table-card">
          <div class="form-group">
            <input type="text" class="form-control search-table" v-model="searchTerm" placeholder="Pesquisar">
          </div>
        </div>
        <table class="table table-hover table-pastel mb-0 mt-0">
          <thead class="thead-dark">
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Tipo de Conta</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-capitalize" v-for="item in filteredList" :key="item.id">
              <td>{{ item.last_name ? item.name + ' ' + item.last_name : item.name }}</td>
              <td class="text-lowercase">{{ item.email }}</td>
              <td>{{ item.account_type}}</td>
              <button class="btn btn-primary" @click="editUser(item.id)">Editar</button>
              <button class="btn btn-danger" @click="deleteUser(item.id)">
                Excluir
              </button>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import "@/assets/css/table.css";

export default {
  data() {
    return {
      searchTerm: '',
      list: [],
      company: localStorage.getItem('company'),
      servidor: 'https://api001.bia.ind.br',
    }
  },
  mounted() {
      this.getList()
  },
  computed: {
    filteredList() {
      const term = this.searchTerm.toLowerCase()

      return this.list.filter(listItem =>
        Object.values(listItem).some(value =>
          String(value).toLowerCase().includes(term)
        )
      );
    },
  },
  methods: {
    getList() {
      axios.get(this.servidor + '/api/users/get-by-company/' + this.company, {
            headers: {
              'Accept': 'application/json',
            },
        })
            .then(response => {
                this.list = response.data
            })
            .catch(error => {
                console.error(error)
            })
    },

    editUser(userId) {
      // Redirecione para a página de criação/editação com o ID do usuário
      this.$router.push({
        path: '/novo-usuario',
        query: { id: userId }
      });
    },

   
    
  },
};

</script>

<style>
  .row-style {
    margin: auto!important;
  }

  .thead-dark > tr > th {
    background-color: #fff!important
  }

  #table-card {
    background-color: #9E14D9db!important;
    box-shadow: #9E14D9 0px 1px 7px 0px;
    border: 0;
    height: 58px;
    padding: 0;
  }

  .modal-title {
    font-weight: bold;
  }

  .btn-purple {
    background-color: #9E14D9db;
    border-color: #9E14D9db;
    color: #fff!important;
  }

  tbody tr {
    line-height: 5;
  }

</style>