<template>
  <div class="container-fluid py-4">
    <div class="row row-style">
      <div class="table-responsive-lg col-12">
        <div class="card col-md-12" id="table-card">
          <div class="form-group">
            <input type="text" class="form-control search-table" v-model="searchTerm" placeholder="Pesquisar">
          </div>
        </div>
        <table class="table table-hover table-pastel mb-0 mt-0">
          <thead class="thead-dark">
            <tr>
              <th>Nome</th>
              <th>CPF/CNPJ</th>
              <th>Tipo de empresa</th>
              <th>Telefone</th>
              <th>Telefone secundário</th>
              <!--<th>Opções</th> -->
            </tr>
          </thead>
          <tbody>
            <tr class="text-capitalize" v-for="item in filteredList" :key="item.id">
              <td>{{ item.company }}</td>
              <td>{{ item.cnpj }}</td>
              <td>{{ item.company_type === 'veterinario' ? 'Veterinário' : 'Laticínio' }}</td>
              <td>{{ item.tel1 }}</td>
              <td>{{ item.tel2 ? item.tel2 : 'Não informado' }}</td>
              <!--
              <td>
                <button class="btn btn-purple rounded-0" @click="editEmpresa(item.id)">Detalhes</button>
              </td>
               -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import "@/assets/css/table.css";

export default {
  data() {
    return {
      searchTerm: '',
      list: [],
      servidor: 'https://api001.bia.ind.br',
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    filteredList() {
      const term = this.searchTerm.toLowerCase()

      return this.list.filter(listItem =>
        Object.values(listItem).some(value =>
          String(value).toLowerCase().includes(term)
        )
      );
    },
  },
  methods: {
    getList() {
      let id = localStorage.getItem('company');
      axios.get(`${this.servidor}/api/companies/${id}/parent-companies`, {
        headers: {
          'Accept': 'application/json',
        },
      })
        .then(response => {
          this.list = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    editEmpresa(item) {
      this.$router.push({
        path: '/nova-empresa',
        query: { id: item }
      });
    },

   
    
  } 
}
</script>

<style>
.row-style {
  margin: auto !important;
}

.thead-dark>tr>th {
  background-color: #fff !important
}

#table-card {
  background-color: #9E14D9db !important;
  box-shadow: #9E14D9 0px 1px 7px 0px;
  border: 0;
  height: 58px;
  padding: 0;
}

.modal-title {
  font-weight: bold;
}

.btn-purple {
  background-color: #9E14D9db;
  border-color: #9E14D9db;
  color: #fff !important;
}

.btn.btn-primary {
  margin-right: 3px;
}


tbody tr {
  line-height: 5;
}
</style>