<template>
  <div v-if="modo === 'criar'">

    <div class="container-fluid py-4">
      <div class="row row-style">
        <div class="col-12">
          <form id="fazenda-form" @submit.prevent="gerarRegistro">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="company">Nome da Empresa</label>
                <input type="text" class="form-control" id="company" v-model="formData.company"
                  placeholder="Digite o nome da empresa" required>
              </div>
              <div class="form-group col-md-6">
                <label for="cnpj">CPF/CNPJ</label>
                <input type="text" class="form-control" id="cnpj" v-model="formData.cnpj" @input="formatCnpj"
                  placeholder="Digite um CPF ou CNPJ" required>
              </div>

              <div class="form-group col-md-6">
                <label for="tel1">Telefone</label>
                <input type="text" class="form-control" id="tel1" v-model="formData.tel1"
                  @input="formatPhoneNumber('tel1')" placeholder="Telefone" required>
              </div>

              <div class="form-group col-md-6">
                <label for="tel2">Whatsapp</label>
                <input type="text" class="form-control" id="tel2" v-model="formData.tel2"
                  @input="formatPhoneNumber('tel2')" placeholder="Whatsapp">
              </div>

              <div class="form-group col-md-6">
                <label for="cep">CEP</label>
                <input type="text" class="form-control" id="cep" v-model="formDataAddress.cep" maxlength="8"
                  placeholder="Digite o CEP" required>
              </div>

              <div class="form-group col-md-6">
                <label for="logradouro">Logradouro</label>
                <input type="text" class="form-control" id="logradouro" v-model="formDataAddress.logradouro"
                  maxlength="255" placeholder="Digite o logradouro" required>
              </div>

              <div class="form-group col-md-6">
                <label for="bairro">Bairro</label>
                <input type="text" class="form-control" id="bairro" v-model="formDataAddress.bairro" maxlength="255"
                  placeholder="Digite o bairro" required>
              </div>

              <div class="form-group col-md-6">
                <label for="localidade">Cidade</label>
                <input type="text" class="form-control" id="localidade" v-model="formDataAddress.localidade"
                  maxlength="255" placeholder="Digite a cidade" required>
              </div>

              <div class="form-group col-md-6">
                <label for="uf">UF</label>
                <input type="text" class="form-control" id="uf" v-model="formDataAddress.uf" placeholder="Digite a UF"
                  maxlength="2" required>
              </div>

              <div class="form-group col-md-6">
                <label for="company_type">Tipo de conta</label>
                <select class="form-control" id="company_type" v-model="formData.company_type" required>
                  <option value="" disabled>SELECIONE UM TIPO DE CONTA</option>
                  <option value="administrador">Administrador</option>
                  <option value="fazenda">Fazenda</option>
                  <option value="laticinio">Laticínio</option>
                  <option value="veterinario">Veterinário</option>
                </select>
              </div>

              <div class="form-group col-5 py-3">
                <button type="submit" class="btn btn-purple w-100">Salvar</button>
              </div>
              <div class="form-group col-5 py-3">
                <button type="submit" @click="deleteEmpresa" class="btn btn-purple w-100">Excluir</button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
  <!-- editar formulario -->
  <div v-if="modo === 'editar'">
    <div class="container-fluid py-4">
      <div class="row row-style">
        <div class="col-12">
          <form id="fazenda-form" @submit.prevent="atualizarRegistro">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="company">Nome da Empresa</label>
                <input type="text" class="form-control" id="company" v-model="formData.company"
                  placeholder="Digite o nome da empresa">
              </div>
              <div class="form-group col-md-6">
                <label for="cnpj">CPF/CNPJ</label>
                <input type="text" class="form-control" id="cnpj" v-model="formData.cnpj" @input="formatCnpj"
                  placeholder="Digite um CPF ou CNPJ">
              </div>

              <div class="form-group col-md-6">
                <label for="tel1">Telefone</label>
                <input type="text" class="form-control" id="tel1" v-model="formData.tel1"
                  @input="formatPhoneNumber('tel1')" placeholder="Telefone">
              </div>

              <div class="form-group col-md-6">
                <label for="tel2">Whatsapp</label>
                <input type="text" class="form-control" id="tel2" v-model="formData.tel2"
                  @input="formatPhoneNumber('tel2')" placeholder="Whatsapp">
              </div>

              <div class="form-group col-md-6">
                <label for="cep">CEP</label>
                <input type="text" class="form-control" id="cep" v-model="formDataAddress.cep" maxlength="8"
                  placeholder="Digite o CEP">
              </div>

              <div class="form-group col-md-6">
                <label for="logradouro">Logradouro</label>
                <input type="text" class="form-control" id="logradouro" v-model="formDataAddress.logradouro"
                  maxlength="255" placeholder="Digite o logradouro">
              </div>

              <div class="form-group col-md-6">
                <label for="bairro">Bairro</label>
                <input type="text" class="form-control" id="bairro" v-model="formDataAddress.bairro" maxlength="255"
                  placeholder="Digite o bairro">
              </div>

              <div class="form-group col-md-6">
                <label for="localidade">Cidade</label>
                <input type="text" class="form-control" id="localidade" v-model="formDataAddress.localidade"
                  maxlength="255" placeholder="Digite a cidade">
              </div>

              <div class="form-group col-md-6">
                <label for="uf">UF</label>
                <input type="text" class="form-control" id="uf" v-model="formDataAddress.uf" placeholder="Digite a UF"
                  maxlength="2">
              </div>

              <div class="form-group col-md-6">
                <label for="company_type">Tipo de conta</label>
                <select class="form-control" id="company_type" v-model="formData.company_type">
                  <option value="" disabled>SELECIONE UM TIPO DE CONTA</option>
                  <option value="administrador">Administrador</option>
                  <option value="fazenda">Fazenda</option>
                  <option value="laticínio">Laticínio</option>
                  <option value="veterinário">Veterinário</option>
                </select>
              </div>

              <div class="form-group col-6 py-3">
                <button type="submit" class="btn btn-purple w-100">Salvar</button>
              </div>
              <div class="form-group col-6 py-3">
                <button type="submit" @click="deleteEmpresa" class="btn btn-danger w-100">Excluir</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  data() {
    return {
      formData: {
        company: '',
        cnpj: '',
        tel1: '',
        tel2: '',
        company_type: '',
        status: 1,
      },
      formDataAddress: {
        cep: '',
        logradouro: '',
        bairro: '',
        localidade: '',
        uf: '',
        fk_company: '',
        status: 1,
      },
      servidor: 'https://api001.bia.ind.br',
      modo: 'criar', // Inicialmente, está no modo de criar

    };
  },
  created() {
    const empresaId = this.$route.query.id;
    if (empresaId) {
      this.getEmpresaData(empresaId);
      this.modo = 'editar';
    }
  },
  methods: {
    async gerarRegistro() {
      if (!this.validarCnpj()) {
        alert('CPF/CNPJ informado é inválido');
        return;
      }

      try {
        // Post para criar ou atualizar dados da empresa
        const response = await axios.post(this.servidor + '/api/companies', this.formData);

        // Atualiza o ID da empresa no objeto de endereço
        this.formDataAddress.fk_company = response.data.id;

        // Post para criar ou atualizar dados do endereço
        const responseAddress = await axios.post(this.servidor + '/api/addresses', this.formDataAddress);

        this.$router.push('/dashboard-empresas');
      } catch (error) {
        console.error(error);
      }
    },

    async atualizarRegistro() {
      try {
        let data = {
          cnpj: this.formData.cnpj,
          company: this.formData.company,
          tel1: this.formData.tel1,
          tel2: this.formData.tel2,
          company_type: this.formData.company_type,
          status: this.formData.status,
        };

        // Adicione campos adicionais de formDataAddress se necessário

        let config = {
          method: 'put',
          maxBodyLength: Infinity,
          url: this.servidor + '/api/companies/' + this.formData.id,
          headers: {
            'Content-Type': 'application/json',
          },
          data: data,
        };


        const response = await axios.request(config);

        console.log('Resposta do servidor:', response.data);
        console.log('Empresa Atualizada');
        this.$router.push('/dashboard-empresas')
      } catch (error) {
        console.error(error);
      }
    },



    async deleteEmpresa() {
      const axios = require('axios');
      let data = JSON.stringify({
        "status": 0
      });

      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: this.servidor + '/api/companies/' + this.formData.id,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

      console.log("Empresa Excluido"),
        //redireciona a rota
        this.$router.push('/dashboard-empresas')


    },


    async getEmpresaData(empresaId) {
      try {
        // Obtem os dados da empresa
        const response = await axios.get(`${this.servidor}/api/companies/${empresaId}`);

        // Atualiza os dados do formulário com os dados da empresa
        this.formData = response.data;

        // Se houver endereço associado à empresa, atualiza os dados do endereço
        if (response.data.address) {
          this.formDataAddress = response.data.address;
        }
      } catch (error) {
        console.error(error);
      }
    },
    formatPhoneNumber(field) {
      // Determine qual campo de telefone está sendo formatado
      let value = this.formData[field].replace(/\D/g, '');

      // Formatação do telefone
      if (/^\d+$/.test(value)) {
        if (value.length >= 11) {
          // Celular
          this.formData[field] = value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3').substring(0, 15);
        } else if (value.length === 10) {
          // Fixo
          this.formData[field] = value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3').substring(0, 14);
        }
      } else {
        this.formData[field] = '';
      }
    },

    formatCnpj() {
      // Remove caracteres não numéricos
      let value = this.formData.cnpj.replace(/\D/g, '');

      // Verifica se é CPF ou CNPJ
      if (value.length <= 11) {
        // CPF
        this.formData.cnpj = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4').substring(0, 18);
      } else {
        // CNPJ
        this.formData.cnpj = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5').substring(0, 18);
      }
    },

    validarCnpj() {
      let documento = this.formData.cnpj.replace(/\D/g, '');

      // Verifica se é um CPF
      if (documento.length === 11) {
        var numeros = documento.match(/\d/g).map(Number);
        var soma = numeros.reduce((acc, cur, idx) => {
          if (idx < 9) {
            return acc + cur * (10 - idx);
          }
          return acc;
        }, 0);

        var resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) {
          resto = 0;
        }

        if (resto !== numeros[9]) {
          return false;
        }

        soma = numeros.reduce((acc, cur, idx) => {
          if (idx < 10) {
            return acc + cur * (11 - idx);
          }
          return acc;
        }, 0);

        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) {
          resto = 0;
        }

        if (resto !== numeros[10]) {
          return false;
        }

        return true;
      }

      // Verifica se é um CNPJ
      if (documento.length === 14) {
        var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
        var c = String(documento).replace(/[^\d]/g, '')

        if (c.length !== 14)
          return false

        if (/0{14}/.test(c))
          return false

        for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
        if (c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
          return false

        for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
        if (c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
          return false

        return true
      }

      // Se não é CPF nem CNPJ
      return false;
    },

  },
};
</script>
  
<style scoped>
.form-control {
  border-color: #ced4da;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}
</style>